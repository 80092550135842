import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const gallery = [
  () => (
    <StaticImage
      className="gallery__image"
      src="../images/gallery0.jpeg"
      alt=""
    />
  ),
  () => (
    <StaticImage
      className="gallery__image"
      src="../images/gallery1.jpeg"
      alt=""
    />
  ),
  () => (
    <StaticImage
      className="gallery__image"
      src="../images/gallery2.jpeg"
      alt=""
    />
  ),
  () => (
    <StaticImage
      className="gallery__image"
      src="../images/gallery3.jpeg"
      alt=""
    />
  ),
  () => (
    <StaticImage
      className="gallery__image"
      src="../images/gallery4.jpeg"
      alt=""
    />
  ),
  () => (
    <StaticImage
      className="gallery__image"
      src="../images/gallery5.jpeg"
      alt=""
    />
  ),
  () => (
    <StaticImage
      className="gallery__image"
      src="../images/gallery6.jpeg"
      alt=""
    />
  ),
];
