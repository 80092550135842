import { gallery } from "constants/galleryImages";
import { StaticImage } from "gatsby-plugin-image";
import { useEventListener } from "hooks/useEventListener";
import { useFocusOut } from "hooks/useFocusOut";
import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import IconChevronLeft from "../svgs/IconChevronLeft.svg";
import IconChevronRight from "../svgs/IconChevronRight.svg";

export default function AboutPage({ location }) {
  const galleryRef = useRef(null);
  const lightboxRef = useRef(null);

  const [lightboxIndex, setLightboxIndex] = useState(-1);
  const lightboxIsOpen = lightboxIndex >= 0;

  useEffect(() => {
    if (lightboxIsOpen) lightboxRef.current?.querySelector("button")?.focus();
  }, [lightboxIsOpen]);

  function lightboxClose() {
    setLightboxIndex((index) => {
      galleryRef.current?.querySelectorAll("button")[index]?.focus();
      return -1;
    });
  }

  function lightboxLeft() {
    setLightboxIndex((index) => {
      index--;
      if (index < 0) index = gallery.length - 1;
      return index;
    });
  }

  function lightboxRight() {
    setLightboxIndex((index) => {
      index++;
      if (index >= gallery.length) index = 0;
      return index;
    });
  }

  useEventListener("keydown", (e) => {
    if (e.key === "Escape") lightboxClose();
    if (e.key === "ArrowLeft") lightboxLeft();
    if (e.key === "ArrowRight") lightboxRight();
  });

  useEventListener("mousedown", (e) => {
    if (!lightboxRef.current.contains(e.target)) lightboxClose();
  });

  useFocusOut(lightboxRef, lightboxClose);

  const title = "About | What's The Motive";
  const description =
    "Expect the unexpected with highly unique concepts, world-class DJ's, super fun props, free food, giveaways and your favourite anthems in the party capital, this is the motive!";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <section className="about__section">
        <StaticImage src="../images/heroAbout.png" alt="" />
      </section>
      <section className="about__section">
        <h1>
          Your girlfriend's fav nights
          <br />
          Find RnB, Hip-Hop, Bashment, Afrobeats, Brunches, Day Parties, Clubs
          Night & Festivals
        </h1>
        <p>
          Expect the unexpected with highly unique concepts, world-class DJ's,
          super fun props, free food, giveaways and your favourite anthems in
          the party capital, this is the motive!
        </p>
      </section>
      <div ref={galleryRef}>
        <section className="gallery__section">
          <ul className="gallery__grid">
            {gallery.map((Image, i) => (
              <li key={i} className="gallery__grid_item">
                <button onClick={() => setLightboxIndex(i)}>
                  <Image />
                </button>
              </li>
            ))}
          </ul>
        </section>
      </div>
      <aside
        className="gallery__lightbox"
        role="dialog"
        hidden={!lightboxIsOpen}
      >
        <div className="gallery__lightbox-content" ref={lightboxRef}>
          <div className="gallery__lightbox-image">
            {gallery.map((Image, i) => (
              <div key={i} aria-hidden={i !== lightboxIndex}>
                <Image />
              </div>
            ))}
          </div>
          <div className="gallery__lightbox-controls">
            <button onClick={lightboxLeft} tabIndex={lightboxIsOpen ? 0 : -1}>
              <IconChevronLeft />
            </button>
            <div />
            <span>
              {lightboxIndex + 1} / {gallery.length + 1}
            </span>
            <div />
            <button onClick={lightboxRight} tabIndex={lightboxIsOpen ? 0 : -1}>
              <IconChevronRight />
            </button>
          </div>
        </div>
      </aside>
    </>
  );
}
